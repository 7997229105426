html {
  box-sizing: border-box;
}

*, *:after, *:before {
  box-sizing: inherit;
}

body {
  background-color: #000000;
  margin: 0;
  overflow-x: hidden;
}

.app {
  --color-disabled: rgba(196, 196, 196, 1);
  --color-middling: #e9c46a;
  --color-negative: #e76f51;
  --color-positive: #06a693;
  --color-positive-gradient-step: #62cca9;
  --white: rgba(255, 255, 255, 0.87);
  --text-body: rgb(136, 136, 136);
  --border: rgb(136, 136, 136, 0.3);
  color: var(--text-body);
  font-size: 14px;
  justify-content: center;
  font-family: Inter, sans-serif;
  display: grid;
  padding: 32px;

  p {
    line-height: 1.5em;
  }

  .register-input {
    background: #00000055;
    border-radius: 8px;
    border: 1px solid var(--border);
    color: #ffffff;
    padding: 16px 16px;
    font-size: 16px;
  }

  .hidden {
    display: none;
  }

  a {
    color: var(--text-body);
    transition: color 250ms;

    &:hover {
      color: var(--white);
    }
  }



  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: Rubik, sans-serif;
    color: var(--white);
  }

  .title-area {
    display: grid;
    gap: 32px;

    h1 {
      font-size: 2.5em;
    }
  }

  .page {
    max-width: 1280px;
    width: 100%;
  }

  .quick-points {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(256px, 400px));
    gap: 32px;
    justify-content: center;
    border-radius: 8px;
  }

  .faq {
    margin-top: 64px;
    display: grid;
    background: #000000cc;
    border-radius: 8px;
    border: 1px solid var(--border);

    .heading {
      padding: 16px;
      border-bottom: 1px solid var(--border);
    }

    .body {
      padding: 16px;
      display: grid;
      gap: 16px;
    }
  }

  .bg1 {
    width: 1200px;
    height: 1200px;
    position: absolute;
    z-index: -2;
    transition: left 1s linear;
    background: radial-gradient(
        ellipse closest-side,
        #62cca9aa 0%,
        rgba(0, 0, 0, 0) 100%);
    left: 10%;
    top: -300px;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .bg2 {
    width: 1000px;
    height: 1000px;
    position: absolute;
    z-index: -2;
    transition: left 1s linear;
    background: radial-gradient(
        ellipse closest-side,
        #e76f51aa 0%,
        rgba(0, 0, 0, 0) 100%);
    left: 30%;
    top: -400px;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .card {
    padding: 16px;
    gap: 16px;
    display: grid;
    grid-auto-rows: auto auto 1fr;

    h2 {
      font-size: 1.5em;
    }
  }

  .hero {
    display: grid;
    grid-template-columns: 4fr 6fr;
    align-items: center;
    margin-top: 240px;
  }

  .call-to-action {
    padding: 12px 64px;
    text-decoration: none;
  }

  nav {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: end;
    align-items: start;
    margin-bottom: 32px;
  }

  button, .call-to-action {
    cursor: pointer;
    color: var(--white);
    border-radius: 64px;
    font-weight: 500;
    padding: 8px 24px;
    font-size: 16px;
    border: none;
    background: linear-gradient(to right, var(--color-positive), var(--color-positive-gradient-step));

    &:hover {
      background: linear-gradient(to right, var(--color-positive), var(--color-positive-gradient-step));
    }

    &.stroked {
      background: linear-gradient(#000, #000) padding-box,
      linear-gradient(to right, var(--color-positive), var(--color-positive-gradient-step)) border-box;
      border: 2px solid transparent;
    }
  }

  .button-like {
    color: var(--white);
    border-radius: 64px;
    font-weight: 500;
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    background: linear-gradient(to right, var(--color-positive), var(--color-positive-gradient-step));

    &:hover {
      background: linear-gradient(to right, var(--color-positive), var(--color-positive-gradient-step));
    }

    &.stroked {
      background: linear-gradient(#000, #000) padding-box,
      linear-gradient(to right, var(--color-positive), var(--color-positive-gradient-step)) border-box;
      border: 2px solid transparent;
    }
  }
}

